import { CONNEXITY_ACCOUNT_ID } from '@/services/Configuration'
import Script from 'next/script'

const ConnexityScript: React.FC = () => (
  <>
    <script
      type="text/javascript"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `window.cnxDataLayer = window.cnxDataLayer || [];
        function cnxtag(){cnxDataLayer.push(arguments);}`,
      }}
    />
    <Script
      type="text/javascript"
      src={`https://js.cnnx.link/roi/cnxtag-min.js?id=${CONNEXITY_ACCOUNT_ID}`}
    />
  </>
)

export default ConnexityScript
